import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function BottomFooter() {
    return (
        <Container>
            <Row noGutters className="mt-5">
                <Col md={12}>
                    <div className="mt-5 py-4 d-flex justify-content-center align-items-center text-muted">
                        Disclaimer: This site is for entertainment only. It does not offer real money gambling or an
                        opportunity to win real money. Results you get from this site does not imply future success at
                        real money gambling.
                    </div>
                </Col>
            </Row>
            <Row noGutters>
                <Col md={12}>
                    <div className="d-flex justify-content-center align-items-center border-top">
                        <a className="mx-4 text-secondary" href="/privacy">
                            Privacy Policy
                        </a>
                        <a className="mx-4 text-secondary" href="/terms">
                            Terms of Service
                        </a>
                        <a className="mx-4 text-secondary" href="mailto:hello@playcards.live">
                            Contact
                        </a>
                        <a className="mx-4 text-secondary" href="/v1.1update">
                            Releases
                        </a>
                    </div>
                </Col>
            </Row>
            <Row noGutters>
                <Col md={12}>
                    <div className="py-4 d-flex justify-content-center align-items-center text-muted">
                        <a className="text-muted" href="https://nerfsoftware.com">
                            Nerf Software LLC
                        </a>
                        . All rights reserved.
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default BottomFooter;
