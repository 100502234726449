import React, { Component } from 'react';
import Badge from 'react-bootstrap/Badge';

function getCardText(card) {
    let rank = Math.floor(card / 4);
    let suit = card % 4;
    let ret = '';
    if (rank < 8) {
        ret += rank + 2;
    } else {
        switch (rank) {
            case 8:
                ret += 'T';
                break;
            case 9:
                ret += 'J';
                break;
            case 10:
                ret += 'Q';
                break;
            case 11:
                ret += 'K';
                break;
            case 12:
                ret += 'A';
                break;
            default:
                break;
        }
    }
    switch (suit) {
        case 0:
            ret += '♥';
            break;
        case 1:
            ret += '♠';
            break;
        case 2:
            ret += '♦';
            break;
        case 3:
            ret += '♣';
            break;
        default:
            break;
    }
    return ret;
}

class TextCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let num = nextProps.card;
        if (num >= 0) {
            prevState.text = getCardText(num);
            prevState.suit = num % 4;
        } else {
            prevState.text = '?';
            prevState.suit = -1;
        }
        return prevState;
    }

    getColor() {
        switch (this.state.suit) {
            case 0:
            case 2:
                return 'danger';
            case 1:
            case 3:
                return 'dark';
            default:
                return 'dark';
        }
    }

    render() {
        return (
            <Badge variant={this.getColor()}>
                <span className="emoji-recolor-white">{this.state.text}</span>
            </Badge>
        );
    }
}

export default TextCard;
