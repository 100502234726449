import React, { Component } from 'react';
import TextCard from './TextCard.js';
import './Players.css';
import Number from './Common';

import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faUser, faUserClock, faCoins, faDollarSign } from '@fortawesome/free-solid-svg-icons';

class Players extends Component {
    constructor(props) {
        super(props);
        this.state = {
            players: []
        };

        this.currentAct = React.createRef();
        this.scrollToAct = this.scrollToAct.bind(this);
    }

    scrollToAct() {
        if (this.currentAct && this.currentAct.current) {
            this.currentAct.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {};

        nextProps.players.sort(function(a, b) {
            return a.position - b.position;
        });

        var players = [];

        if (nextProps.playerId >= 0) {
            for (let i = nextProps.playerId; i < nextProps.players.length; i++) {
                players.push(nextProps.players[i]);
            }
            for (let i = 0; i < nextProps.playerId; i++) {
                players.push(nextProps.players[i]);
            }

            players[0].you = true;
        } else {
            players = nextProps.players;
        }

        /*
        if (prevState.players) {
            for (let i = 0; i < prevState.players.length; i++) {
                if (prevState.players[i].stack < players[i].stack) {
                    players[i].increase = true;
                }
                if (prevState.players[i].stack > players[i].stack) {
                    players[i].decrease = true;
                }
            }
        }
        */

        nextState.players = players;
        nextState.toAct = nextProps.toAct;

        nextState.hand0 = nextProps.hand0;
        nextState.hand1 = nextProps.hand1;

        nextState.winners = nextProps.winners;

        return nextState;
    }

    render() {
        return (
            <ListGroup className="player-list">
                {/*
                <ListGroup.Item key="title" variant="dark">
                    <Row noGutters>
                        <Col xs={1}></Col>
                        <Col xs={1}></Col>
                        <Col xs={2}>Pos</Col>
                        <Col xs={2}>Name</Col>
                        <Col xs={2}>Stack </Col>
                        <Col xs={2}>Bet </Col>
                        <Col xs={2}>Cards</Col>
                    </Row>
                </ListGroup.Item>
                    */}
                {this.state.players.map((item, index) => (
                    <ListGroup.Item
                        key={index}
                        ref={this.state.toAct >= 0 && item.position === this.state.toAct ? this.currentAct : null}
                        variant={
                            this.state.winners && this.state.winners.indexOf(item.position) >= 0
                                ? 'success'
                                : item.inPlay
                                ? this.state.toAct >= 0 && item.position === this.state.toAct
                                    ? 'primary'
                                    : item.away
                                    ? 'light'
                                    : null
                                : 'danger'
                        }
                    >
                        <Row
                            noGutters
                            className={this.state.toAct >= 0 && item.position === this.state.toAct ? 'pulse' : null}
                        >
                            <Col xs={1}>
                                {this.state.toAct >= 0 && item.position === this.state.toAct ? (
                                    <FontAwesomeIcon icon={faCaretRight} className="current" />
                                ) : item.away ? (
                                    <FontAwesomeIcon icon={faUserClock} />
                                ) : (
                                    <FontAwesomeIcon icon={faUser} />
                                )}
                            </Col>
                            <Col xs={1} className="list-sm">
                                {item.position === this.state.players.length - 1 ? (
                                    <Badge pill variant={item.you ? 'primary' : 'dark'}>
                                        D
                                    </Badge>
                                ) : item.you ? (
                                    <Badge pill variant="primary">
                                        me
                                    </Badge>
                                ) : null}
                            </Col>
                            <Col xs={2} className="list-sm">
                                {item.name}
                            </Col>
                            <Col xs={2} className="list-sm">
                                <FontAwesomeIcon icon={faDollarSign} />{' '}
                                <Number number={item.rebuys} alwaysK={true} precision={0} />
                            </Col>
                            <Col
                                xs={2}
                                className={
                                    'list-sm ' + (item.decrease ? 'decrease' : item.increase ? 'increase' : null)
                                }
                            >
                                <FontAwesomeIcon icon={faCoins} /> <Number number={item.stack} precision={1} />
                            </Col>
                            <Col xs={2} className="list-sm">
                                {item.inPlay ? (
                                    item.bet && item.bet > 0 ? (
                                        <Badge key={item.bet} className="pop" pill variant="dark">
                                            <Number number={item.bet} />
                                        </Badge>
                                    ) : item.position < this.state.toAct || this.state.toAct === -1 ? (
                                        <Badge className="pop" pill variant="dark">
                                            Check
                                        </Badge>
                                    ) : null
                                ) : (
                                    <Badge className="pop" pill variant="danger">
                                        Fold
                                    </Badge>
                                )}
                            </Col>
                            <Col xs={2}>
                                {index === 0 && this.props.playerId >= 0 ? (
                                    this.props.showCards || (this.state.winners && this.state.winners.length > 0) ? (
                                        <>
                                            <TextCard card={this.state.hand0} />
                                            <TextCard card={this.state.hand1} />
                                        </>
                                    ) : (
                                        <>
                                            <TextCard card={-1} />
                                            <TextCard card={-1} />
                                        </>
                                    )
                                ) : (
                                    <>
                                        <TextCard card={item.hand0} />
                                        <TextCard card={item.hand1} />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    }
}

export default Players;
