import React, { Component } from 'react';

class Number extends Component {
    constructor(props) {
        super(props);
        this.state = {
            number: 0
        };

        this.getNumber = this.getNumber.bind(this);
    }

    getNumber() {
        if (this.props.number > 0) {
        } else {
            return '0';
        }

        if (this.props.number >= 100000 || this.props.alwaysK) {
            return (this.props.number / 1000.0).toFixed(this.props.precision).toString() + 'K';
        } else {
            return this.props.number.toString();
        }
    }

    render() {
        return <>{this.getNumber()}</>;
    }
}

export default Number;
