import React, { Component } from 'react';
import Grid from './Grid.js';
import NewGameModal from './NewGameModal.js';
import RebuyModal from './RebuyModal.js';
import Dimmer from './Dimmer.js';
import CheckinModal from './CheckinModal.js';
import { GetCardUrl } from './Card.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const URL = 'wss://api.playcards.live';

const toastConfig = {
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
};

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table: '',
            state: {},
            intervalId: -1,
            showNewGame: false,
            showRebuy: false,
            ready: false,
            defaultTable: '',
            maxPlayers: 6,
            isPrivate: true
        };

        this.checkinSuppressed = false;

        this.ws = new WebSocket(URL);

        this.createTable = this.createTable.bind(this);
        this.joinTable = this.joinTable.bind(this);
        this.leaveTable = this.leaveTable.bind(this);
        this.rebuy = this.rebuy.bind(this);
        this.randomGame = this.randomGame.bind(this);
        this.away = this.away.bind(this);
        this.checkin = this.checkin.bind(this);

        this.fold = this.fold.bind(this);
        this.check = this.check.bind(this);
        this.call = this.call.bind(this);
        this.bet = this.bet.bind(this);
        this.raise = this.raise.bind(this);
        this.allin = this.allin.bind(this);

        this.reassociate = this.reassociate.bind(this);
        this.showTable = this.showTable.bind(this);
        this.setupWsCallbacks = this.setupWsCallbacks.bind(this);
    }

    componentWillUnmount() {
        if (this.state.intervalId !== -1) {
            clearInterval(this.state.intervalId);
        }
    }

    reassociate() {
        this.setState({ ready: false });
        let playerHash = sessionStorage.getItem('playerHash');
        if (playerHash) {
            this.ws.send(
                JSON.stringify({
                    action: 'associate',
                    playerHash: playerHash
                })
            );
        } else {
            this.ws.send(
                JSON.stringify({
                    action: 'associate'
                })
            );
        }
    }

    showTable() {
        this.refs.dimmer.showTable(
            this.state.table,
            this.state.maxPlayers,
            this.state.stack,
            this.state.sb,
            this.state.isPrivate
        );
    }

    setupWsCallbacks() {
        this.ws.onopen = () => {
            this.reassociate();
        };

        this.ws.onclose = () => {
            if (this.state.intervalId !== -1) {
                clearInterval(this.state.intervalId);
                this.setState({ intervalId: -1 });
            }
            this.setState({ ready: false });

            // automatically try to reconnect on connection loss
            this.ws = new WebSocket(URL);
            this.setupWsCallbacks();
        };

        this.ws.onerror = event => {};

        this.ws.onmessage = evt => {
            const message = JSON.parse(evt.data);
            if (message.playerHash) {
                let playerHash = sessionStorage.getItem('playerHash');
                if (playerHash !== message.playerHash) {
                    sessionStorage.setItem('playerHash', message.playerHash);
                }
                this.heartbeat(true);

                if (this.props.isPractice && this.state.table === '') {
                    if (message.table) {
                    } else {
                        this.createTable('Player', 100, 100, 6, true, true);
                    }
                }
            }

            if (message.rebought) {
                this.heartbeat(true);
                this.refs.rebuyModal.onReset();
                this.setState({ showRebuy: false });
            }

            if (message.noTable) {
                let playerHash = sessionStorage.getItem('playerHash');
                if (!playerHash) {
                    this.reassociate();
                }
                if (this.state.intervalId !== -1) {
                    clearInterval(this.state.intervalId);
                    this.setState({ intervalId: -1 });
                }

                if (this.props.isPractice) {
                } else {
                    this.setState({ ready: true });

                    const params = new URLSearchParams(window.location.search);
                    if (params.has('table')) {
                        this.setState({ defaultTable: params.get('table') });
                    }

                    this.setState({ showNewGame: true });
                }
                this.setState({ showRebuy: false, table: '' });
                window.onbeforeunload = null;
            }

            if (message.table) {
                this.setState({
                    table: message.table,
                    stack: message.stack,
                    sb: message.sb,
                    maxPlayers: message.maxNumPlayers,
                    isPrivate: message.isPrivate
                });
                this.refs.grid.resetTable();
                this.setState({ ready: true });
                this.setState({ showNewGame: false });
                this.heartbeat(true);
                if (this.state.intervalId === -1) {
                    let intervalId = setInterval(() => this.heartbeat(false), 4000);
                    this.setState({ intervalId: intervalId });
                }
                this.refs.newGameModal.onReset();
                if (message.isPrivate && message.create && !message.isPractice) {
                    this.showTable();
                }
                window.onbeforeunload = function() {
                    return 'Leave current game?';
                };
            }

            if (message.errorMessage) {
                if (message.errorMessage === 'You have been disconnected') {
                    this.reassociate();
                }
                this.refs.newGameModal.onReset();
                this.refs.rebuyModal.onReset();
                this.refs.grid.resetClick();
                toast.error(message.errorMessage, toastConfig);
            }

            if (message.message === 'Internal server error') {
                toast.error('Something went wrong', toastConfig);
            }

            if (message.rebuy) {
                this.setState({ showRebuy: true });
            }

            if (message.timeout >= 0) {
                this.refs.grid.setTimeout(message.timeout);
            }

            if (message.timeoutIsSelf) {
                if (message.checkinTimeout >= 0) {
                    if (this.checkinSuppressed) {
                        this.checkinSuppressed = false;
                    } else {
                        this.refs.checkin.show(true);
                    }
                } else {
                    this.checkinSuppressed = false;
                }
            } else if (!message.state) {
                this.refs.checkin.show(false);
            } else if (!message.state.joined && !message.state.left) {
                this.refs.checkin.show(false);
            }

            if (message.state) {
                this.refs.grid.resetClick();
                this.setState({ state: message });

                if (message.state.joined) {
                    toast.info(message.state.joined + ' joined', toastConfig);
                }

                if (message.state.left) {
                    toast.error(message.state.left + ' left', toastConfig);
                }

                this.refs.grid.scrollToAct();
            }
        };
    }

    componentDidMount() {
        this.setupWsCallbacks();
        // Preload images
        for (let i = 0; i < 52; ++i) {
            const img = new Image();
            img.src = GetCardUrl(i);
        }
    }

    createTable = (name, stack, sb, maxPlayers, isPrivate, isPractice) => {
        this.ws.send(
            JSON.stringify({
                action: 'createTable',
                stack: stack,
                sb: sb,
                name: name,
                isPrivate: isPrivate,
                maxPlayers: maxPlayers,
                isPractice: isPractice
            })
        );
    };

    joinTable = (name, tableId) => {
        this.ws.send(
            JSON.stringify({
                action: 'joinTable',
                table: tableId,
                name: name
            })
        );
    };

    randomGame = name => {
        this.ws.send(
            JSON.stringify({
                action: 'joinTable',
                name: name
            })
        );
    };

    away = isAway => {
        this.ws.send(
            JSON.stringify({
                action: 'away',
                away: isAway
            })
        );
    };

    leaveTable = (name, tableId) => {
        this.ws.send(
            JSON.stringify({
                action: 'leaveTable'
            })
        );
        sessionStorage.removeItem('playerHash');
        if (this.props.isPractice) {
            window.onbeforeunload = null;
            window.location = '/';
        }
    };

    rebuy = amount => {
        this.ws.send(
            JSON.stringify({
                action: 'rebuy',
                amount: amount
            })
        );
    };

    heartbeat = requestState => {
        this.ws.send(
            JSON.stringify({
                action: 'heartbeat',
                requestState: requestState
            })
        );
    };

    fold = () => {
        this.ws.send(
            JSON.stringify({
                action: 'act',
                act: 'fold'
            })
        );
    };

    call = () => {
        this.ws.send(
            JSON.stringify({
                action: 'act',
                act: 'call'
            })
        );
    };

    check = () => {
        this.ws.send(
            JSON.stringify({
                action: 'act',
                act: 'check'
            })
        );
    };

    raise = amount => {
        this.ws.send(
            JSON.stringify({
                action: 'act',
                act: 'raise',
                amount: amount
            })
        );
    };

    bet = amount => {
        this.ws.send(
            JSON.stringify({
                action: 'act',
                act: 'bet',
                amount: amount
            })
        );
    };

    allin = () => {
        this.ws.send(
            JSON.stringify({
                action: 'act',
                act: 'allin'
            })
        );
    };

    checkin() {
        this.checkinSuppressed = true;
        this.ws.send(
            JSON.stringify({
                action: 'checkin'
            })
        );
    }

    render() {
        return (
            <div>
                <Dimmer ref="dimmer" showLoading={!this.state.ready} />
                <Grid
                    ref="grid"
                    state={this.state.state}
                    table={this.state.table}
                    stack={this.state.stack}
                    sb={this.state.sb}
                    fold={this.fold}
                    check={this.check}
                    call={this.call}
                    bet={this.bet}
                    raise={this.raise}
                    allin={this.allin}
                    leaveTable={this.leaveTable}
                    away={this.away}
                    showInfo={this.showTable}
                    demo={false}
                    checkin={this.checkin}
                />
                <NewGameModal
                    ref="newGameModal"
                    show={this.state.showNewGame}
                    create={this.createTable}
                    join={this.joinTable}
                    randomGame={this.randomGame}
                    defaultTable={this.state.defaultTable}
                />
                <RebuyModal
                    ref="rebuyModal"
                    show={this.state.showRebuy}
                    rebuy={this.rebuy}
                    leave={this.leaveTable}
                    stack={this.state.stack}
                />
                <CheckinModal ref="checkin" checkin={this.checkin} />
                <ToastContainer />
            </div>
        );
    }
}

export default Table;
