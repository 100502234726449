import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Toggle from '../Toggle';

class NewGameModal extends Component {
    constructor(props) {
        super(props);

        this.handleSelectTab = this.handleSelectTab.bind(this);
        this.buttonName = this.buttonName.bind(this);
        this.handleStackChange = this.handleStackChange.bind(this);
        this.handleSBChange = this.handleSBChange.bind(this);
        this.startGame = this.startGame.bind(this);
        this.onReset = this.onReset.bind(this);
        this.randomGame = this.randomGame.bind(this);

        this.playerName = React.createRef();
        this.tableName = React.createRef();

        this.state = {
            show: false,
            selectedTab: 'join',
            stack: 100,
            sb: 100,
            processing: false,
            defaultTable: '',
            tableText: '',
            defaultSet: false,
            maxPlayers: 6,
            privateTable: true,
            privateTableDisabled: false
        };

        this.handleTableChange = this.handleTableChange.bind(this);
        this.changeNumPlayers = this.changeNumPlayers.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.handleEnterPress = this.handleEnterPress.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.show = nextProps.show;
        prevState.defaultTable = nextProps.defaultTable;
        if (!prevState.defaultSet && nextProps.defaultTable !== '') {
            prevState.tableText = nextProps.defaultTable;
            prevState.defaultSet = true;
        }
        return prevState;
    }

    handleTableChange() {
        this.setState({ tableText: this.tableName.current.value });
    }

    handleSelectTab(key, event) {
        this.setState({ selectedTab: key });
    }

    handleStackChange(value) {
        this.setState({ stack: value });
    }

    handleSBChange(value) {
        this.setState({ sb: value });
    }

    handleExit() {
        window.location = '/';
    }

    handleEnterPress(target) {
        if (target.charCode === 13) {
            if (this.state.tableText !== '' || this.state.selectedTab !== 'join') {
                this.startGame();
            }
        }
    }

    buttonName() {
        if (this.state.selectedTab === 'create') {
            return 'Create';
        } else {
            return 'Join';
        }
    }

    startGame() {
        this.setState({ processing: true });
        if (this.state.selectedTab === 'create') {
            this.props.create(
                this.playerName.current.value,
                this.state.stack,
                this.state.sb,
                this.state.maxPlayers,
                this.state.privateTable && !this.state.privateTableDisabled,
                false
            );
        } else {
            this.props.join(this.playerName.current.value, this.tableName.current.value);
        }
    }

    randomGame() {
        this.setState({ processing: true });
        this.props.randomGame(this.playerName.current.value);
    }

    onReset() {
        this.setState({ processing: false });
    }

    changeNumPlayers(numPlayers) {
        if (numPlayers > 6) {
            this.setState({ maxPlayers: numPlayers, privateTableDisabled: false });
        } else {
            this.setState({ maxPlayers: numPlayers, privateTableDisabled: false });
        }
    }

    render() {
        return (
            <>
                <Modal show={this.state.show} backdrop={'static'} onHide={this.handleExit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Start Game</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey={this.state.selectedTab}
                            className="mb-2"
                            onSelect={this.handleSelectTab}
                        >
                            <Tab eventKey="create" title="Create Table">
                                <Container>
                                    <Row noGutters>
                                        <Col>
                                            <Slider
                                                step={null}
                                                min={5}
                                                max={500}
                                                marks={{
                                                    5: '',
                                                    10: '',
                                                    20: '',
                                                    30: '',
                                                    50: '',
                                                    100: '',
                                                    200: '',
                                                    300: '',
                                                    500: ''
                                                }}
                                                value={this.state.sb}
                                                onChange={this.handleSBChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col>
                                            <Form.Label>{'Small blind: ' + this.state.sb}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col>
                                            <Slider
                                                step={null}
                                                min={100}
                                                max={200}
                                                marks={{
                                                    100: '',
                                                    150: '',
                                                    200: ''
                                                }}
                                                value={this.state.stack}
                                                onChange={this.handleStackChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col>
                                            <Form.Label>{'Stack size: ' + this.state.stack + ' Big Blinds'}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row noGutters className="mt-2">
                                        <Col>
                                            <Button
                                                variant={this.state.maxPlayers === 6 ? 'primary' : 'light'}
                                                onClick={() => this.changeNumPlayers(6)}
                                            >
                                                6-max (up to 6 players)
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                variant={this.state.maxPlayers === 6 ? 'light' : 'primary'}
                                                onClick={() => this.changeNumPlayers(9)}
                                            >
                                                Full ring (up to 9 players)
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row noGutters className="mt-3">
                                        <Col>
                                            <Form.Label className="mr-2">{'Private'}</Form.Label>
                                            <Toggle
                                                defaultChecked={this.state.privateTable}
                                                onChange={checked => this.setState({ privateTable: checked })}
                                                disabled={this.state.privateTableDisabled}
                                            />
                                            {this.state.privateTable && !this.state.privateTableDisabled ? null : (
                                                <span className="ml-2">Anyone in the world can join</span>
                                            )}
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="join" title="Join Table">
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Table ID</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        ref={this.tableName}
                                        type="text"
                                        defaultValue={this.state.defaultTable}
                                        onChange={this.handleTableChange}
                                        onKeyPress={this.handleEnterPress}
                                    />
                                </InputGroup>
                            </Tab>
                        </Tabs>
                        <InputGroup className="mt-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Player Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl ref={this.playerName} onKeyPress={this.handleEnterPress} />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            onClick={this.randomGame}
                            disabled={this.state.processing}
                            className="mr-auto"
                        >
                            {this.state.processing ? (
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            ) : (
                                'Random Table'
                            )}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.startGame}
                            disabled={
                                this.state.processing ||
                                (this.state.tableText === '' && this.state.selectedTab === 'join')
                            }
                        >
                            {this.state.processing ? (
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            ) : (
                                this.buttonName()
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default NewGameModal;
