import React, { Component } from 'react';
import './TopNav.css';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Number from './components/Common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class TopNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: '',
            isTable: false
        };

        this.showInfo = this.showInfo.bind(this);
        this.leaveTable = this.leaveTable.bind(this);
    }

    showInfo(e) {
        e.preventDefault();
        this.props.showInfo();
    }

    leaveTable(e) {
        e.preventDefault();

        var ok = window.confirm('Exit game?');
        if (ok) {
            this.props.leaveTable();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.info) {
            prevState.info = nextProps.info;
            prevState.isTable = false;
        } else if (nextProps.table) {
            prevState.info =
                'Table: ' +
                (nextProps.table === -1 ? 'PUBLIC' : nextProps.table === -2 ? 'PRACTICE' : nextProps.table) +
                ' Pot: ';
            /*
            if (nextProps.maxPlayers === 9) {
                prevState.info = 'Table ' + nextProps.table + ' (full-ring) Blinds: 100/200 Pot: ' + nextProps.pot;
            } else {
                prevState.info = 'Table ' + nextProps.table + ' (6-max) Blinds: 100/200 Pot: ' + nextProps.pot;
            }
            */
            prevState.isTable = true;
        } else {
            prevState.info = '';
            prevState.isTable = false;
        }
        return prevState;
    }

    render() {
        return (
            <Navbar className="top-navbar flex-nowrap" sticky="top" expand="sm">
                <Navbar.Brand className="brand" href="/">
                    PlayCards
                </Navbar.Brand>
                {this.state.isTable ? (
                    <>
                        <a href="/#" onClick={this.showInfo}>
                            <FontAwesomeIcon className="text-warning" icon={faInfoCircle} />
                            &nbsp;
                            <span className="navbar-info">
                                {this.state.info}
                                <Number number={this.props.pot} precision={1} />
                            </span>
                        </a>
                        &nbsp; &nbsp;
                        <Button size="sm" onClick={this.leaveTable} variant="danger">
                            Exit
                        </Button>
                    </>
                ) : (
                    <span className="navbar-info">{this.state.info}</span>
                )}
                {this.props.demo ? <Button onClick={this.props.demoClick}>next</Button> : null}
            </Navbar>
        );
    }
}

export default TopNav;
