import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import './Dimmer.css';
var QRCode = require('qrcode.react');

class Dimmer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showTable: false,
            table: '',
            maxPlayers: 6,
            stack: 100,
            sb: 100,
            isPrivate: true
        };

        this.closeTable = this.closeTable.bind(this);
        this.showTable = this.showTable.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.showLoading = nextProps.showLoading;
        return prevState;
    }

    showTable(table, maxPlayers, stack, sb, isPrivate) {
        this.setState({
            showTable: true,
            table: table,
            maxPlayers: maxPlayers,
            stack: stack,
            sb: sb,
            isPrivate: isPrivate
        });
    }

    closeTable() {
        this.setState({ showTable: false });
    }

    render() {
        return (
            <>
                <Modal show={this.state.showLoading} centered={true} backdrop={'static'} size="sm">
                    <Modal.Body>Loading...</Modal.Body>
                </Modal>
                <Modal show={this.state.showTable} onHide={this.closeTable}>
                    <Modal.Header closeButton>
                        <Modal.Title>Table Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.table < 0 ? null : (
                            <>
                                Join this table by using table ID:
                                <br />
                                <center>
                                    <code>{this.state.table}</code>
                                </center>
                                <br />
                                Or scan the QR code below
                                <center>
                                    <QRCode
                                        value={'https://playcards.live/play?table=' + this.state.table}
                                        size={128}
                                    />
                                </center>
                                <br />
                            </>
                        )}
                        <Table striped borderless size="sm">
                            <tbody>
                                <tr>
                                    <td>Stack</td>
                                    <td>{this.state.stack}</td>
                                </tr>
                                <tr>
                                    <td>Blinds</td>
                                    <td>{this.state.sb + '/' + this.state.sb * 2}</td>
                                </tr>
                                <tr>
                                    <td>Max players</td>
                                    <td>{this.state.maxPlayers}</td>
                                </tr>
                                <tr>
                                    <td>Public/Private</td>
                                    <td>{this.state.isPrivate ? 'Private' : 'Public'}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeTable}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default Dimmer;
