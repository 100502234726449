import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class CheckinModal extends Component {
    constructor(props) {
        super(props);

        this.onConfirm = this.onConfirm.bind(this);
        this.show = this.show.bind(this);

        this.state = {
            show: false,
            leaveProcessing: false,
            rebuyProcessing: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return prevState;
    }

    onConfirm() {
        this.setState({ show: false });
        this.props.checkin();
    }

    show(doShow) {
        this.setState({ show: doShow });
    }

    render() {
        return (
            <>
                <Modal show={this.state.show} backdrop={'static'}>
                    <Modal.Header>
                        <Modal.Title>Are you still there?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.onConfirm}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default CheckinModal;
