import React, { Component } from 'react';
import Card from './Card.js';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Common.css';

class Control extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showControl: false,
            showWinnerCards: false,
            myBet: 0,
            myStack: 0,
            maxBet: 0,
            pot: 0,
            bet: 0,
            betMin: 0,
            betMax: 0,
            clicked: false
        };

        this.checkCall = this.checkCall.bind(this);
        this.betRaise = this.betRaise.bind(this);
        this.getBetAmount = this.getBetAmount.bind(this);
        this.getRaiseAmount = this.getRaiseAmount.bind(this);
        this.allin = this.allin.bind(this);
        this.fold = this.fold.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);

        this.sliderTimeoutId = -1;
    }

    fold() {
        this.setState({ clicked: true });
        this.props.fold();
    }

    checkCall() {
        this.setState({ clicked: true });
        if (this.state.myBet < this.state.maxBet) {
            this.props.call();
        } else {
            this.props.check();
        }
    }

    allin() {
        var ok = window.confirm('Go All-in?');
        if (ok) {
            this.setState({ clicked: true });
            this.props.allin();
        }
    }

    showButton(x) {
        if (this.state.maxBet === 0) {
            // BET
            const betAmount = this.getBetAmount(x);
            if (this.state.myStack >= betAmount && betAmount >= this.state.betMin) {
                return true;
            } else {
                return false;
            }
        }

        if (this.state.maxBet > 0) {
            // RAISE
            const raiseAmount = this.getRaiseAmount(x);
            if (this.state.myStack + this.state.myBet >= raiseAmount) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }

    buttonText(x) {
        if (this.state.myBet === 0 && this.state.maxBet === 0) {
            return this.getBetAmount(x);
        } else {
            return this.getRaiseAmount(x);
        }
    }

    getBetAmount(x) {
        let r = 0;
        switch (x) {
            case 0:
                r = 0.25;
                break;
            case 1:
                r = 0.5;
                break;
            case 2:
                r = 0.75;
                break;
            default:
                r = 0.5;
                break;
        }
        return Math.round(this.state.pot * r);
    }

    getRaiseAmount(x) {
        let r = 0;
        switch (x) {
            case 0:
                r = 2.0;
                break;
            case 1:
                r = 3.0;
                break;
            case 2:
                r = 4.0;
                break;
            default:
                r = 3.0;
                break;
        }
        return Math.round(this.state.maxBet * r);
    }

    betRaise(x, amount) {
        this.setState({ clicked: true });
        if (this.state.myBet === 0 && this.state.maxBet === 0) {
            if (x < 0) {
                this.props.bet(amount);
            } else {
                this.props.bet(this.getBetAmount(x));
            }
        } else {
            if (x < 0) {
                this.props.raise(amount);
            } else {
                this.props.raise(this.getRaiseAmount(x));
            }
        }
    }

    handleSliderChange(value) {
        this.setState({ bet: value });
        if (this.sliderTimeoutId === -1) {
            this.props.checkin();
            this.sliderTimeoutId = setTimeout(() => {
                this.sliderTimeoutId = -1;
            }, 2000);
        }
    }

    resetClick() {
        this.setState({ clicked: false });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.showControl =
            nextProps.state.toAct === nextProps.state.playerId &&
            nextProps.state.toAct >= 0 &&
            !prevState.clicked &&
            !nextProps.meAway;
        prevState.myBet = nextProps.state.myBet;
        prevState.myStack = nextProps.state.myStack;
        prevState.maxBet = nextProps.state.maxBet;
        prevState.pot = nextProps.state.pot;

        prevState.betMax = nextProps.state.myStack;
        if (nextProps.state.maxBet === 0) {
            prevState.betMin = nextProps.state.sb * 2;
        } else {
            prevState.betMin = nextProps.state.maxBet * 2;
        }
        if (prevState.bet < prevState.betMin) {
            prevState.bet = prevState.betMin;
        }

        if (!prevState.showControl) {
            // Reset slider when user can't see it
            prevState.bet = 0;
            prevState.betMin = 0;
            prevState.betMax = 0;
        }

        if (nextProps.state.winners && nextProps.state.players) {
            prevState.winnerCards = [];

            const numPlayers = nextProps.state.players.length;
            for (let i = 0; i < numPlayers; i++) {
                if (i === nextProps.state.playerId) {
                    continue;
                }

                const player = nextProps.state.players[i];
                if (player.hand0 >= 0) {
                    prevState.winnerCards.push(player);
                }
            }
        }

        prevState.winnerHand0 = nextProps.state.winnerHand0;
        prevState.winnerHand1 = nextProps.state.winnerHand1;

        prevState.maxExcludeI = nextProps.state.maxExcludeI;
        prevState.maxExcludeJ = nextProps.state.maxExcludeJ;

        return prevState;
    }

    render() {
        return (
            <Container>
                {this.state.showControl ? (
                    <div>
                        {this.state.myBet < this.state.maxBet ? (
                            <Row className="mb-2" noGutters>
                                <Col>
                                    <Button block size="sm" variant="danger" onClick={this.fold}>
                                        <b>Fold</b>
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                        {this.state.myStack + this.state.myBet >= this.state.maxBet ? (
                            <Row className="mb-2" noGutters>
                                <Col>
                                    <Button block size="sm" variant="success" onClick={this.checkCall}>
                                        <b>
                                            {this.state.myBet < this.state.maxBet
                                                ? 'Call ' + this.state.maxBet
                                                : 'Check'}
                                        </b>
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                        <Row className="mb-2" noGutters>
                            {this.showButton(0) ? (
                                <Col className="pr-2">
                                    <Button block size="sm" onClick={() => this.betRaise(0, null)}>
                                        <b>{this.buttonText(0)}</b>
                                    </Button>
                                </Col>
                            ) : null}
                            {this.showButton(1) ? (
                                <Col className="pr-2">
                                    <Button block size="sm" onClick={() => this.betRaise(1, null)}>
                                        <b>{this.buttonText(1)}</b>
                                    </Button>
                                </Col>
                            ) : null}
                            {this.showButton(2) ? (
                                <Col>
                                    <Button block size="sm" onClick={() => this.betRaise(2, null)}>
                                        <b>{this.buttonText(2)}</b>
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                        {this.state.betMin <= this.state.betMax ? (
                            <Row className="mb-2" noGutters>
                                <Col className="px-2">
                                    <Slider
                                        step={1}
                                        min={this.state.betMin}
                                        max={this.state.betMax}
                                        value={this.state.bet}
                                        onChange={this.handleSliderChange}
                                    />
                                </Col>
                                <Col xs={4} className="pl-1">
                                    <Button block size="sm" onClick={() => this.betRaise(-1, this.state.bet)}>
                                        <b>{this.state.bet}</b>
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                        <Row className="mb-2" noGutters>
                            <Col>
                                <Button block size="sm" variant="warning" onClick={this.allin}>
                                    <b>ALL-IN</b>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : null}
                {this.state.winnerCards && this.state.winnerCards.length > 0 ? (
                    <Row noGutters>
                        <Col xs={6}>
                            {this.state.winnerCards.map((item, index) => (
                                <Row noGutters key={index} className="mb-2">
                                    <Col>
                                        <Card
                                            card={item.hand0}
                                            used={
                                                this.props.state.winners.indexOf(item.position) >= 0 &&
                                                item.excludeI !== 5 &&
                                                item.excludeJ !== 5
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Card
                                            card={item.hand1}
                                            used={
                                                this.props.state.winners.indexOf(item.position) >= 0 &&
                                                item.excludeI !== 6 &&
                                                item.excludeJ !== 6
                                            }
                                        />
                                        <img
                                            className="img-fluid"
                                            alt="hidden"
                                            src={'cards/back.jpg'}
                                            style={{ opacity: 0 }}
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                ) : null}
            </Container>
        );
    }
}

export default Control;
