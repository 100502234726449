import React, { Component } from 'react';
import './Grid.css';
import Card from './Card.js';
import Control from './Control.js';
import Players from './Players.js';
import './Players.css';
import './Common.css';
import TopNav from '../TopNav';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';

class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            cardWidth: 0,
            myBet: 0,
            myStack: 0,
            maxBet: 0,
            hand0: null,
            hand1: null,
            flop: [null, null, null],
            turn: null,
            river: null,
            players: [],
            toAct: 0,
            toActName: '',
            msg: ' ',
            timeout: 0,
            showCards: true,
            showCardsMouseDown: false,
            meAway: false,
            awayButtonDisabled: false,
            pot: 0,
            currHandId: -1
        };

        setInterval(
            function() {
                if (this.state.timeout > 0) {
                    this.setState({ timeout: this.state.timeout - 1 });
                }
            }.bind(this),
            1000
        );

        this.setTimeout = this.setTimeout.bind(this);
        this.resetClick = this.resetClick.bind(this);
    }

    componentDidMount() {
        this.refs.touch.addEventListener(
            'mousedown',
            event => {
                event.preventDefault();
                this.setState({ showCardsMouseDown: true });
            },
            { passive: false }
        );
        this.refs.touch.addEventListener('mouseup', () => {
            this.setState({ showCardsMouseDown: false });
        });
        this.refs.touch.addEventListener(
            'touchstart',
            event => {
                event.preventDefault();
                this.setState({ showCardsMouseDown: true });
            },
            { passive: false }
        );
        this.refs.touch.addEventListener(
            'touchend',
            event => {
                event.preventDefault();
                this.setState({ showCardsMouseDown: false });
            },
            { passive: false }
        );
    }

    resetClick() {
        this.refs.control.resetClick();
    }

    setTimeout(timeout) {
        this.setState({ timeout: timeout });
    }

    scrollToAct() {
        if (this.refs.players) {
            this.refs.players.scrollToAct();
        }
    }

    resetTable() {
        this.setState({ currHandId: -1 });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.state.state && nextProps.state.state.handId < prevState.currHandId) {
            return prevState;
        }

        prevState.table = nextProps.table;
        prevState.stack = nextProps.stack;
        prevState.sb = nextProps.sb;
        prevState.flop = [-1, -1, -1];
        prevState.turn = -1;
        prevState.river = -1;
        prevState.pot = 0;
        prevState.hand0 = -1;
        prevState.hand1 = -1;
        prevState.fold = true;
        prevState.maxExcludeI = -1;
        prevState.maxExcludeJ = -1;
        if (nextProps.state.state) {
            prevState.currHandId = nextProps.state.state.handId;
            if (prevState.toAct !== nextProps.state.state.toAct) {
                prevState.toAct = nextProps.state.state.toAct;
            }
            if (nextProps.state.state.prevToAct) {
                prevState.prevToAct = nextProps.state.state.prevToAct.toString();
                prevState.prevAction = nextProps.state.state.prevAction;
                prevState.prevAmount = nextProps.state.state.prevAmount ? nextProps.state.state.prevAmount : '';
            }
            var street = 0;
            prevState.river = nextProps.state.state.river;
            if (prevState.river >= 0) {
                street = 3;
            } else {
                street = 2;
            }
            prevState.turn = nextProps.state.state.turn;
            if (prevState.turn >= 0) {
            } else {
                street = 1;
            }
            if (nextProps.state.state.flop) {
                prevState.flop = nextProps.state.state.flop;
            } else {
                street = 0;
            }
            prevState.pot = nextProps.state.state.pot;
            if (nextProps.state.state.players) {
                prevState.players = nextProps.state.state.players;
                const numPlayers = nextProps.state.state.players.length;
                const playerId = nextProps.state.playerId;
                var maxBet = 0;
                const toAct = nextProps.state.state.toAct;

                let winnerNames = [];
                for (let i = 0; i < numPlayers; i++) {
                    let p = nextProps.state.state.players[i];
                    const pos = p.position;
                    if (pos === toAct) {
                        prevState.toActName = p.name;
                    }
                    if (playerId === pos) {
                        prevState.myBet = p.bet;
                        prevState.myStack = p.stack;
                        if (p.inPlay) {
                            prevState.fold = false;
                        }
                        if (p.away === 1) {
                            if (!prevState.meAway) {
                                prevState.meAway = true;
                                prevState.awayButtonDisabled = false;
                            }
                        } else {
                            if (prevState.meAway) {
                                prevState.meAway = false;
                                prevState.awayButtonDisabled = false;
                            }
                        }
                        prevState.practiceWinning = p.practiceWinning;
                    }
                    if (p.bet > maxBet) {
                        maxBet = p.bet;
                    }
                    if (nextProps.state.state.winners) {
                        const winnerPos = nextProps.state.state.winners.indexOf(pos);
                        if (winnerPos >= 0) {
                            if (winnerPos === 0) {
                                prevState.winnerHand0 = p.hand0;
                                prevState.winnerHand1 = p.hand1;
                                prevState.maxExcludeI = p.excludeI;
                                prevState.maxExcludeJ = p.excludeJ;
                            }
                            winnerNames.push(p.name);
                        }
                    }
                }
                prevState.maxBet = maxBet;

                if (nextProps.state.state.players.length <= 1) {
                    prevState.msg = 'Waiting for players to join...';
                } else if (nextProps.state.state.winners && nextProps.state.state.winners.length > 0) {
                    if (nextProps.state.state.winners.indexOf(nextProps.state.playerId) >= 0) {
                        prevState.msg = 'You won!';
                    } else {
                        if (winnerNames.length > 1) {
                            prevState.msg = 'Winners: ' + winnerNames.join(', ');
                        } else {
                            prevState.msg = 'Winner: ' + winnerNames[0];
                        }
                    }
                } else if (nextProps.state.state.stalled === 1) {
                    prevState.msg = 'Waiting for other players...';
                } else if (nextProps.state.state.toAct < 0) {
                    switch (street) {
                        case 0:
                            prevState.msg = 'Get ready for Flop...';
                            break;
                        case 1:
                            prevState.msg = 'Get ready for Turn...';
                            break;
                        case 2:
                            prevState.msg = 'Get ready for River...';
                            break;
                        default:
                            prevState.msg = 'Please wait...';
                            break;
                    }
                } else if (nextProps.state.state.toAct !== nextProps.state.playerId && prevState.toActName) {
                    prevState.msg = prevState.toActName + "'s turn";
                } else {
                    prevState.msg = ' ';
                }
            }

            prevState.winners = nextProps.state.state.winners;
        }
        prevState.hand0 = nextProps.state.hand0;
        prevState.hand1 = nextProps.state.hand1;
        prevState.playerId = nextProps.state.playerId;

        let dimCard0 = false;
        let dimCard1 = false;
        if (prevState.fold) {
            dimCard0 = true;
            dimCard1 = true;
        }
        if (prevState.winners && prevState.winners.length > 0) {
            if (prevState.winners.indexOf(prevState.playerId) < 0) {
                dimCard0 = true;
                dimCard1 = true;
            } else {
                if (prevState.maxExcludeI === 5 || prevState.maxExcludeJ === 5) {
                    dimCard0 = true;
                }
                if (prevState.maxExcludeI === 6 || prevState.maxExcludeJ === 6) {
                    dimCard1 = true;
                }
            }
        }
        prevState.dimCard0 = dimCard0;
        prevState.dimCard1 = dimCard1;

        return prevState;
    }

    render() {
        return (
            <Container id="container">
                <Row noGutters>
                    <Col xs={12} sm={6}>
                        <Row noGutters>
                            <Col>
                                <TopNav
                                    table={this.state.table}
                                    pot={this.state.pot}
                                    showInfo={this.props.showInfo}
                                    leaveTable={this.props.leaveTable}
                                    demo={this.props.demo}
                                    demoClick={this.props.demoClick}
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col id="players">
                                <Players
                                    ref="players"
                                    players={this.state.players}
                                    playerId={this.state.playerId}
                                    toAct={this.state.toAct}
                                    hand0={this.state.hand0}
                                    hand1={this.state.hand1}
                                    winners={this.state.winners}
                                    showCards={this.state.showCards}
                                />
                            </Col>
                        </Row>
                        {this.state.practiceWinning > 0 || this.state.practiceWinning < 0 ? (
                            <Row noGutters className="mt-2">
                                <Col>
                                    Your total winning:{' '}
                                    <span className={'text-' + (this.state.practiceWinning > 0 ? 'success' : 'danger')}>
                                        {this.state.practiceWinning}
                                    </span>
                                </Col>
                            </Row>
                        ) : null}
                    </Col>
                    <Col xs={12} sm={6} id="non-players">
                        <Row noGutters className="mb-2 mx-2">
                            <Col id="communalCards">
                                <Row noGutters>
                                    <Col>
                                        <Card
                                            card={this.state.flop[0]}
                                            used={this.state.maxExcludeI !== 0 && this.state.maxExcludeJ !== 0}
                                        />
                                    </Col>
                                    <Col>
                                        <Card
                                            card={this.state.flop[1]}
                                            used={this.state.maxExcludeI !== 1 && this.state.maxExcludeJ !== 1}
                                        />
                                    </Col>
                                    <Col>
                                        <Card
                                            card={this.state.flop[2]}
                                            used={this.state.maxExcludeI !== 2 && this.state.maxExcludeJ !== 2}
                                        />
                                    </Col>
                                    <Col className="ml-4">
                                        <Card
                                            card={this.state.turn}
                                            used={this.state.maxExcludeI !== 3 && this.state.maxExcludeJ !== 3}
                                        />
                                    </Col>
                                    <Col className="ml-4">
                                        <Card
                                            card={this.state.river}
                                            used={this.state.maxExcludeI !== 4 && this.state.maxExcludeJ !== 4}
                                        />
                                        <img
                                            className="img-fluid"
                                            alt="hidden"
                                            src={'cards/back.jpg'}
                                            style={{ opacity: 0 }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col className="info-text">{this.state.msg}</Col>
                        </Row>
                        <Row noGutters className="mb-2 mx-2">
                            <Col>
                                <ProgressBar
                                    variant={this.state.timeout > 5 ? 'dark' : 'danger'}
                                    now={(this.state.timeout * 100) / 15}
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col
                                xs={4}
                                className={
                                    this.state.playerId >= 0 || !this.state.players || this.state.players.length <= 1
                                        ? 'hide-player-cards'
                                        : ''
                                }
                            >
                                Observing
                            </Col>
                            <Col
                                xs={4}
                                id="playerCards"
                                className={this.state.playerId >= 0 ? '' : 'hide-player-cards'}
                            >
                                <Row noGutters className="ml-2 mb-2">
                                    <Col>
                                        <Button
                                            block
                                            size="sm"
                                            variant={this.state.meAway ? 'success' : 'danger'}
                                            disabled={this.state.awayButtonDisabled}
                                            onClick={() => {
                                                let ok = true;
                                                if (!this.state.fold && !this.state.meAway) {
                                                    ok = window.confirm('Are you sure? You are still in the hand.');
                                                }
                                                if (ok) {
                                                    this.setState({ awayButtonDisabled: true });
                                                    this.props.away(!this.state.meAway);
                                                }
                                            }}
                                        >
                                            <b>
                                                {this.state.meAway ? (
                                                    "I'm Back"
                                                ) : (
                                                    <FontAwesomeIcon icon={faUserClock} />
                                                )}
                                                {this.state.meAway ? null : ' Away'}
                                            </b>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row noGutters className="ml-2 mb-2">
                                    <Col>
                                        <Button
                                            block
                                            size="sm"
                                            variant={this.state.showCards ? 'primary' : 'outline-primary'}
                                            onClick={() => {
                                                this.setState({ showCards: !this.state.showCards });
                                            }}
                                            className="shrink-button-text"
                                        >
                                            <b>{this.state.showCards ? 'Hide cards' : 'Show cards'}</b>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row noGutters className="ml-2" ref="touch">
                                    <Col>
                                        <Card
                                            fast={true}
                                            card={this.state.hand0}
                                            used={!this.state.dimCard0}
                                            show={this.state.showCards || this.state.showCardsMouseDown}
                                        />
                                    </Col>
                                    <Col>
                                        <Card
                                            fast={true}
                                            card={this.state.hand1}
                                            used={!this.state.dimCard1}
                                            show={this.state.showCards || this.state.showCardsMouseDown}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Badge className="disable-touch" variant="dark">
                                            {!this.state.showCards && !this.state.showCardsMouseDown
                                                ? 'Touch & hold to reveal'
                                                : ''}
                                        </Badge>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8} id="control">
                                <Control
                                    ref="control"
                                    state={this.state}
                                    fold={this.props.fold}
                                    check={this.props.check}
                                    call={this.props.call}
                                    bet={this.props.bet}
                                    raise={this.props.raise}
                                    allin={this.props.allin}
                                    meAway={this.state.meAway}
                                    checkin={this.props.checkin}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Grid;
