import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from 'rc-slider';

class RebuyModal extends Component {
    constructor(props) {
        super(props);

        this.onReset = this.onReset.bind(this);
        this.onLeaveClick = this.onLeaveClick.bind(this);
        this.onRebuyClick = this.onRebuyClick.bind(this);

        this.state = {
            show: false,
            leaveProcessing: false,
            rebuyProcessing: false,
            amount: 0
        };

        this.handleValueChange = this.handleValueChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.show = nextProps.show;
        if (prevState.amount >= nextProps.stack / 5) {
        } else {
            prevState.amount = nextProps.stack;
        }
        return prevState;
    }

    onReset() {
        this.setState({ leaveProcessing: false, rebuyProcessing: false });
    }

    onLeaveClick() {
        this.setState({ leaveProcessing: true });
        this.props.leave();
    }

    onRebuyClick() {
        this.setState({ rebuyProcessing: true });
        this.props.rebuy(this.state.amount);
    }

    handleValueChange(value) {
        this.setState({ amount: value });
    }

    render() {
        return (
            <>
                <Modal show={this.state.show} onHide={this.onLeaveClick}>
                    <Modal.Header>
                        <Modal.Title>Rebuy?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You lost! Rebuy to enter again?
                        <Row>
                            <Col className="mx-2">
                                <Slider
                                    step={1}
                                    min={Math.floor(this.props.stack / 5)}
                                    max={this.props.stack}
                                    value={this.state.amount}
                                    onChange={this.handleValueChange}
                                />
                            </Col>
                        </Row>
                        {'Rebuy amount: ' + this.state.amount}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onLeaveClick}
                            disabled={this.state.leaveProcessing || this.state.rebuyProcessing}
                        >
                            {this.state.leaveProcessing ? (
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            ) : (
                                'Leave'
                            )}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.onRebuyClick}
                            disabled={this.state.leaveProcessing || this.state.rebuyProcessing}
                        >
                            {this.state.rebuyProcessing ? (
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            ) : (
                                'Rebuy'
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default RebuyModal;
