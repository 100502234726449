import React, { Component } from 'react';
import './App.css';
import Table from './components/Table.js';
import Display from './components/Display.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import TopNav from './TopNav';
import BottomFooter from './BottomFooter';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navbarInfo: '',
            validated: false,
            processing: false
        };

        this.demo = false;
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'Play social poker, online | Free home game for No limit Holdem';
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({ validated: true });
            return;
        }

        this.setState({ processing: true });

        let request = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        };
        fetch('https://rapi.playcards.live/signup?email=' + this.refs.email.value, request).finally(() => {
            this.setState({ processing: false });
            this.setState({ validated: true });
        });
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/play">
                        <div className="App">{this.demo ? <Display /> : <Table isPractice={false} />}</div>
                    </Route>
                    <Route path="/practice">
                        <div className="App">
                            <Table isPractice={true} />
                        </div>
                    </Route>
                    <Route path="/v1.1update">
                        <TopNav info={'Release v1.1'} />
                        <Container className="mt-5">
                            <div className="nav-space"></div>

                            <Row>
                                <Col md={8}>
                                    <h1>Release v1.1</h1>
                                    <h3>4/17/2020</h3>
                                    <ul>
                                        <li>
                                            You can now select the blinds when creating a table. You can choose from
                                            between 5 - 500 as the small blind.
                                        </li>
                                        <li>
                                            You can now select the rebuy amount, between 0.2x and 1x the max stack size.
                                        </li>
                                        <li>A new, more robust random number generator (RNG) is now in use.</li>
                                        <li>Player buy-in amounts are now tracked in the player list.</li>
                                        <li>Bug fixes.</li>
                                    </ul>
                                    <Button href="/play" size="lg" className="mt-1 ml-1">
                                        Play Now
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <BottomFooter />
                    </Route>
                    <Route path="/terms">
                        <TopNav info={'Terms of Service'} />
                        <Container className="mt-5">
                            <div className="nav-space"></div>

                            <Row>
                                <Col md={8}>
                                    <p>
                                        Nerf Software LLC (hereinafter referred to as the Operator) operates the website
                                        playcards.live, a web-based application for playing card games. These Terms of
                                        Service apply to all users (hereinafter: the User) of the platform
                                        playcards.live.
                                    </p>

                                    <p>
                                        By using any services on playcards.live, the User agrees to the following terms
                                        and conditions.
                                    </p>

                                    <p>There is no warranty for the results of the software and its availability.</p>

                                    <p>
                                        The Operator is not liable for the speed of the Software, its Availability, data
                                        loss or the correctness of the results. In addition, the liability of the
                                        Operator is excluded unless the damage has been caused intentionally or through
                                        gross negligence.
                                    </p>

                                    <p>
                                        The Operator has the right to restrict the use of the Platform without giving
                                        reasons or to block Users.
                                    </p>

                                    <p>
                                        The User undertakes to refrain from any actions that endanger the functionality
                                        or operation of the software. In particular, the User is prohibited from
                                        carrying out any actions that scan or test weak points of the software, bypass
                                        security systems or access systems of the software or integrate malware into the
                                        software.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                        <BottomFooter />
                    </Route>
                    <Route path="/privacy">
                        <TopNav info={'Privacy Policy'} />
                        <Container className="mt-5">
                            <div className="nav-space"></div>
                            <Row>
                                <Col md={8}>
                                    <h2>Personal identification information</h2>

                                    <p>
                                        We collect email addresses of registered Users and any other information
                                        voluntarily entered into forms on the Site. We also collect registered Users'
                                        account creation dates and the number of times a User has signed in. None of
                                        this information is sold or provided to third parties, except to provide the
                                        products and services you've requested, with your permission, or as required by
                                        law.
                                    </p>

                                    <h2>Non-personal identification information</h2>

                                    <p>
                                        We may collect non-personal identification information about Users whenever they
                                        interact with the Site, This may include: the browser name, the type of
                                        computer, the operating system, and other similar information.
                                    </p>

                                    <h2>Web browser cookies</h2>

                                    <p>
                                        The Site may use "cookies" to enhance User experience. Users may choose to set
                                        their web browser to refuse cookies, or to indicate when cookies are being sent.
                                        Note that this may cause some parts of the Site to function improperly.
                                    </p>

                                    <h2>How we use collected information</h2>

                                    <p>
                                        Nerf Software LLC collects and uses Users' personal information for the
                                        following purposes:
                                    </p>
                                    <ul>
                                        <li>
                                            To personalize user experience: to understand how our Users as a group use
                                            the Site.
                                        </li>
                                        <li>To improve our Site.</li>
                                        <li>To improve customer service.</li>
                                        <li>
                                            To process transactions: We may use the information Users provide about
                                            themselves when placing an order only to provide service to that order. We
                                            do not share this information with outside parties except to the extent
                                            necessary to provide the service.
                                        </li>
                                        <li>
                                            To send periodic emails: The email address Users provide for order
                                            processing will be used to send them information and updates pertaining to
                                            their order. It may also be used to respond to their inquiries, and/or other
                                            requests or questions. The User may also receive company news, updates,
                                            related product or service information, etc. If at any time the User would
                                            like to unsubscribe from receiving future emails, we include detailed
                                            unsubscribe instructions at the bottom of each email.
                                        </li>
                                        <li>To prevent fraud.</li>
                                    </ul>

                                    <h2>Where we send your data</h2>

                                    <ul>
                                        <li>Billing providers. These let us charge you for the services we provide.</li>
                                        <li>
                                            Financial metrics aggregators, which let us make informed decisions about
                                            the finances of our company. Services that maintain our website. Your
                                            information may appear in error-tracking software if you encounter an error
                                            with our website. This is used to notify you of any technical problems that
                                            may affect your account.
                                        </li>
                                        <li>
                                            Non-personal identification information may be sent to analytics software.
                                            No personal data or data that could uniquely identify a User will be sent.
                                        </li>
                                    </ul>

                                    <h2>GDPR legal basis for collecting your information</h2>

                                    <p>
                                        We collect the minimum amount of information needed to provide the products and
                                        services you've requested. How we collect and use your data is covered by our
                                        legitimate interest.
                                    </p>

                                    <h2>Your rights under GDPR</h2>

                                    <p>You may:</p>
                                    <ul>
                                        <li>Be informed whether we store your data.</li>
                                        <li>
                                            Be granted access to your data (with some stipulations – see GDPR Article 15
                                            for the precise terms. Note that we store so little of your data that it may
                                            require more information to authenticate your request than information we
                                            actually hold).
                                        </li>
                                        <li>
                                            Correct any errors in our records – for instance, update your email address.
                                        </li>
                                        <li>Request that your records be erased.</li>
                                        <li>
                                            Restrict our processing of your data. This means that we will continue to
                                            store your data, but will not use it.
                                        </li>
                                        <li>Object to our use of your data.</li>
                                        <li>
                                            Request the data we hold be provided to you or to another service. See GDPR
                                            Article 20 for the precise terms.
                                        </li>
                                        <li>Not be subject to decisions based entirely on automated processing.</li>
                                    </ul>
                                    <p>
                                        Exercising some of these rights might interfere with our ability to provide you
                                        with our services. For instance, if you request your records be erased,
                                        information about your purchase history will also be removed.
                                    </p>
                                    <p>To exercise any of these rights, please write to support@nerfsoftware.com.</p>

                                    <h2>How we protect your information</h2>

                                    <p>
                                        We adopt appropriate data collection, storage and processing practices and
                                        security measures to protect against unauthorized access, alteration, disclosure
                                        or destruction of your personal information, username, password, transaction
                                        information and data stored on our Site.
                                    </p>
                                    <p>
                                        Sensitive and private data exchange between the Site and its Users happens over
                                        an SSL secured communication channel and is encrypted and protected with digital
                                        signatures.
                                    </p>

                                    <h2>Sharing your personal information</h2>

                                    <p>
                                        We do not sell, trade, or rent Users' personal identification information to
                                        others. We may share generic aggregated demographic information not linked to
                                        any personal identification information regarding visitors and users with our
                                        business partners and trusted affiliates for the purposes outlined above.
                                    </p>

                                    <h2>Compliance with children's online privacy protection act</h2>

                                    <p>
                                        Protecting the privacy of the very young is especially important. For that
                                        reason, no part of our website is structured to attract anyone under 13.
                                    </p>

                                    <h2>Changes to this privacy policy</h2>

                                    <p>
                                        Nerf Software LLC has the discretion to update this privacy policy at any time.
                                        When we do, we will revise the updated date at the bottom of this page.
                                    </p>

                                    <h2>Contacting us</h2>

                                    <p>Questions about this policy can be sent to support@nerfsoftware.com.</p>
                                    <p>This document was last updated on Mar 12, 2020.</p>
                                </Col>
                            </Row>
                        </Container>
                        <BottomFooter />
                    </Route>
                    <Route path="/">
                        <TopNav info={''} />
                        <Container className="title-container">
                            <div className="nav-space"></div>
                            <Row noGutters className="title justify-content-center">
                                <Col lg={6}>Play Poker</Col>
                                <Col xs={4}></Col>
                            </Row>
                            <Row noGutters className="justify-content-center align-items-end">
                                <Col lg={6} xs={8} className="subtitle">
                                    No Cards Or Chips Needed!
                                </Col>
                                <Col xs={4} className="text-right d-flex flex-row-reverse">
                                    <Row noGutters>
                                        <Col>
                                            <Button href="/practice" size="lg" variant="dark" className="mt-1">
                                                Practice
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button href="/play" size="lg" className="mt-1 ml-1">
                                                &nbsp;&nbsp;Play&nbsp;&nbsp;
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row noGutters className="justify-content-center mt-2 text-right">
                                <Col lg={6}></Col>
                                <Col lg={4}>
                                    Play No Limit Texas Hold'em
                                    <br />
                                    More games in the future
                                </Col>
                            </Row>
                            <div className="title-space"></div>
                            <Row noGutters className="mt-5">
                                <Col md={4} className="text-nowrap text-right mb-5">
                                    <img src="/cards/0S.png" className="playcard hand0" alt="" />
                                    <img src="/cards/0H.png" className="playcard hand1" alt="" />
                                </Col>
                                <Col md={4} className="text-nowrap text-center mb-5">
                                    <div className="card-container">
                                        <img src="/cards/back.jpg" className="playcard backs0" alt="" />
                                        <img src="/cards/back.jpg" className="playcard backs1" alt="" />
                                    </div>
                                </Col>
                                <Col md={4} className="text-nowrap text-left mb-5">
                                    <img src="/cards/AD.png" className="playcard hand2" alt="" />
                                    <img src="/cards/KD.png" className="playcard hand3" alt="" />
                                </Col>
                            </Row>
                            <Row noGutters className="mt-5 border-bottom">
                                <Col className="text-center section">
                                    Wanna play poker with friends, but no cards or chips?
                                </Col>
                            </Row>
                            <Row noGutters className="mt-2">
                                <Col className="text-center section-text text-muted">
                                    We've got everything you need to play poker
                                    <br />
                                    Up to 9 players on a single table
                                    <br />
                                    Support <strong>re-buy</strong>, <strong>multi-way pot</strong>, and more
                                </Col>
                            </Row>
                            <Row noGutters className="mt-5 border-bottom">
                                <Col className="text-center section">No Download · No Registration · No Real-money</Col>
                            </Row>
                            <Row noGutters className="mt-2">
                                <Col className="text-center section-text text-muted">
                                    Free to play
                                    <br />
                                    Works on any device with a browser
                                    <br />
                                    One click to start playing
                                    <br />
                                    Perfect for casual home games, and more
                                </Col>
                            </Row>
                            <Row noGutters className="mt-5 mb-5 justify-content-center">
                                <Col sm={2} xs={3} className="text-center symbols text-secondary">
                                    ♠
                                </Col>
                                <Col sm={2} xs={3} className="text-center symbols text-danger">
                                    ♥
                                </Col>
                                <Col sm={2} xs={3} className="text-center symbols text-primary">
                                    ♦
                                </Col>
                                <Col sm={2} xs={3} className="text-center symbols text-success">
                                    ♣
                                </Col>
                            </Row>
                            <Row noGutters className="mt-5 border-bottom">
                                <Col className="text-center section">Sign up and get feature updates!</Col>
                            </Row>
                            <Form
                                noValidate
                                validated={this.state.validated}
                                onSubmit={this.handleSubmit}
                                className="justify-content-center"
                                inline
                            >
                                <Form.Row className="mt-2 justify-content-center">
                                    <Col className="mr-2">
                                        <Form.Group controlId="validationCustomEmail">
                                            <Form.Control
                                                ref="email"
                                                className="mr-2"
                                                required
                                                type="email"
                                                placeholder="Enter email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid email address
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" type="submit" disabled={this.state.processing}>
                                            {this.state.processing ? (
                                                <Spinner animation="border" role="status" size="sm">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            ) : (
                                                'Sign Up'
                                            )}
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Container>
                        <BottomFooter />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default App;
