import React, { Component } from 'react';
import './Toggle.css';

class Toggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: '',
            isTable: false
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        this.props.onChange(this.refs.checkbox.checked);
    }

    render() {
        return (
            <label className="switch">
                <input
                    ref="checkbox"
                    type="checkbox"
                    defaultChecked={this.props.defaultChecked ? true : false}
                    onChange={this.onChange}
                    disabled={this.props.disabled}
                />
                <span className="slider round"></span>
            </label>
        );
    }
}

export default Toggle;
