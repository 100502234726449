import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://fe79c9c52f6a4e2ebdffbfe53e9b53c0@o399199.ingest.sentry.io/5258982"});

ReactDOM.render(<App />, document.getElementById('root'));
