import React, { Component } from 'react';
import Grid from './Grid.js';

class Display extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table: '',
            state: {
                state: {
                    players: [
                        {
                            name: 'Ian',
                            stack: 10000,
                            rebuys: 0,
                            bet: 100,
                            position: 0,
                            inPlay: true
                        },
                        {
                            name: 'Sam',
                            stack: 999890,
                            rebuys: 0,
                            bet: 100,
                            position: 1,
                            inPlay: true
                        },
                        {
                            name: 'Sue',
                            stack: 10000,
                            rebuys: 0,
                            bet: 100,
                            position: 2,
                            inPlay: true,
                            away: true
                        },
                        {
                            name: 'Joe',
                            stack: 10000,
                            rebuys: 2,
                            bet: 200,
                            position: 3,
                            inPlay: true
                        },
                        {
                            name: 'Jack',
                            stack: 10000,
                            rebuys: 3,
                            bet: 100,
                            position: 4,
                            inPlay: true
                        },
                        {
                            name: 'Ben',
                            stack: 10000,
                            rebuys: 0,
                            bet: 0,
                            position: 5,
                            inPlay: true
                        },
                        {
                            name: 'Ben',
                            stack: 10000,
                            rebuys: 0,
                            bet: 0,
                            position: 6,
                            inPlay: true
                        },
                        {
                            name: 'Ben',
                            stack: 10000,
                            rebuys: 0,
                            bet: 0,
                            position: 7,
                            inPlay: true
                        },
                        {
                            name: 'Ben',
                            stack: 10000,
                            rebuys: 0,
                            bet: 0,
                            position: 8,
                            inPlay: true
                        }
                    ],
                    flop: [-1, 26, 45],
                    turn: 51,
                    river: 9,
                    pot: 2340,
                    prevToAct: 2,
                    prevAction: 'bet',
                    prevAmount: 100,
                    winners: [1, 4],
                    toAct: 3
                },
                hand0: 10,
                hand1: 11,
                playerId: 3,
                maxExcludeI: 0,
                maxExcludeJ: 3
            }
        };

        this.test = this.test.bind(this);

        /*
        window.setTimeout(
            function() {
                this.test();
            }.bind(this),
            5000
        );
        */
    }

    test() {
        let stateCopy = JSON.parse(JSON.stringify(this.state.state));
        stateCopy.state.toAct = 1;
        stateCopy.state.players[2].bet = 400;
        // stateCopy.state.players[3].inPlay = false;
        stateCopy.state.players[3].stack = 18000;
        stateCopy.state.flop[0] = 32;
        this.setState({ state: stateCopy });
        this.refs.grid.scrollToAct();
    }

    render() {
        return (
            <div>
                <Grid ref="grid" state={this.state.state} table={this.state.table} demo={true} demoClick={this.test} />
            </div>
        );
    }
}

export default Display;
